<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label ">
        {{ $t('branch_config.branch_config') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803">
            <button type="button" class="btn btn-primary btn-sm" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
              <thead>
              <tr class="text-center">
                <th width="30%">{{ $t('branch_config.type') }}</th>
                <th width="30%">{{ $t('branch_config.type_selected') }}</th>
                <th width="10%">{{ $t('branch_config.is_active') }}</th>
                <th width="20%" class="text-center"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cu, index) in data" :key="index">
                <td>
                  <select name="" id="data_type" v-model="cu.type_type" @change="changeType(index)" class="custom-select">
                    <option v-for="(row, index) in data_type_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                </td>
                <td>
                  <multiselect v-model="cu.type" v-if="cu.type_type == 'bank'"
                               :placeholder="$t('branch_config.type_selected')"
                               label="name"
                               track-by="id"
                               @input="changeValue(index)"
                               :options="banks"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getBanks($event)">
                  </multiselect>
                  <multiselect v-model="cu.type" v-else
                               :placeholder="$t('branch_config.type_selected')"
                               label="name"
                               track-by="id"
                               @input="changeValue(index)"
                               :options="treasuries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>
                <td class="text-center">
                  <b-form-checkbox size="lg" v-model="cu.is_active" :name="'check-button'+cu.id" switch :key="cu.id"></b-form-checkbox>
                </td>

                <td class="text-center">
                  <v-icon class="text-danger" @click="cu.id ? deleteItem(cu) : deleteFromRepeater(index)">mdi-delete</v-icon>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-branch-config",
  data() {
    return {
      mainRoute: 'settings/branch-config',
      mainRouteDependency: 'base/dependency',
      banks: [],
      treasuries: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      validation: null,
      repeater: {id: null, branch_id: (this.$route.params.id ? this.$route.params.id : null), type_type: 'bank', type_id: null, type: null, is_active: false},
      data: [],
      data_type_list: [],
    };
  },
  methods: {
    save() {
      ApiService.post(this.mainRoute, {
        config: this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'branches.index'});
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    async getData() {
      await ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.data = response.data.data;
        if (response.data.data.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getTypeList() {
      ApiService.get(this.mainRouteDependency + "/branch-config-types").then((response) => {
        this.data_type_list = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },

    addToRepeater() {
      this.data.unshift(this.repeater);
      this.repeater = {id: null, branch_id: (this.$route.params.id ? this.$route.params.id : null), type_type: 'bank', type_id: null, type: null, is_active: false};
    },
    deleteFromRepeater(index) {
      this.data.splice(index, 1);
    },

    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getData();
        this.$successAlert(response.data.message)
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    changeValue(index) {
      this.data[index].type_id = this.data[index].type.id;
    },
    changeType(index) {
      this.data[index].type_id = null;
      this.data[index].type = null;
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.branch_config")}]);
    this.getTreasuries();
    this.getTypeList();
    let that = this;

    if (this.idEdit) {
      let promise = this.getData();
      Promise.all([promise]).then(() => {
        if (that.data.length <= 0) {
          that.addToRepeater();
        }
      });
    } else {
      if (that.data.length <= 0) {
        that.addToRepeater();
      }
    }
  },
};
</script>


